$color-white: #ffffff;
$color-off-white: #f5f5f5;
$color-blue-grey: #2e61a4;
$color-blue: #162e4d;
$color-blue-dark: #0a1d35;
$color-blue-dark-1: #102643;
$color-blue-light: #526783;
$color-blue-light-1: #8294ac;
$color-blue-lighter: #97a4b5;
$color-blue-lightest: #c0c8d4;
$color-blue-lightest-2: #e6e9f1;
$bg-color-blue-lightest-2: rgba(230, 233, 241, 0.15);
$color-blue-lightest-3: #dbdfea;
$color-blue-lightest-4: #c1c8d3;
$color-blue-lghtest-5: #f7f8fa;
$color-blue-lghtest-6: #f6f9fc;
$color-blue-lghtest-7: #e1e5ec;
$color-blue-lightest-8: #fbfcfd;
$color-blue-lightest-9: #b6b6b6;
$color-blue-lightest-10: #f7f8fb;
$color-primary: #e07e1f;
$color-primary-1: #c86c13;
$color-primary-light: #efaf45;
$color-primary-lightest: rgba(255, 187, 68, 0.15);
$color-primary-lightest-1: rgba(224, 126, 31, 0.6);
$color-primary-lightest-2: rgba(224, 126, 31, 0.15);
$color-primary-lightest-3: #ecab3a;
$color-yellow-light: #fadfc2;
$color-yellow-lightest: rgb(255, 187, 68, 0.15);
$color-green: #4ee17b;
$color-green-lightest: rgba(20, 178, 61, 0.6);
$color-green-lighter: rgba(20, 178, 61, 0.15);
$color-dark-green: #14b23d;
$color-darkest-green: #0b882c;
$color-dark-cyan: #098784;
$color-red: #d0021b;
$color-red-1: rgba(230, 47, 70, 0.6);
$color-red-2: rgba(230, 47, 70, 0.15);
$color-red-3: #e62f46;
$color-sky: #4a90e2;
$color-sky-light: #5c8fdc;
$color-sky-lightest: #678cfc;
$color-sky-lightest1: rgba(103, 140, 252, 0.15);
$color-sky-lightest2: rgba(103, 140, 252, 0.6);
$color-sky-lightest3: #3d64da;
$color-cyan: #16b3af;

$color-lightbg: #f7f9fb;
$color-lightbg-2: #f6f9fc;
$color-grey-dark: #5f5f5f;
$color-grey: #5f646a;
$color-dark-blue: #344863;
$color-grey-light: #d9d9d9;
$color-blue2: #626e7c;
$color-pink: #de1382;
$color-dark-pink: #ac0c64;

// @import "~bootstrap/dist/css/bootstrap.css";
// if you'd like to use it without importing all of bootstrap it requires
// @import "~bootstrap/scss/functions";
// @import "~bootstrap/scss/variables";
// @import "~bootstrap/scss/mixins";

// @import "~@ng-select/ng-select/themes/default.theme.css";
@import "~font-awesome/css/font-awesome.css";
// @import url("https://fonts.googleapis.com/css?family=Lato:400,900");
// @import "spinner";
// @import "~bootstrap";
@import "utils";
@import "rtl";
@import "responsive";
@import "main";
@import "~ngx-toastr/toastr-bs4-alert";
@import "calender";
@import "mapsAutocomplete";
@import "border-radius";
//@import "~ng-pick-datetime/assets/style/picker.min.css";
@import "@angular/material/prebuilt-themes/deeppurple-amber.css";
@import "~@danielmoncada/angular-datetime-picker/assets/style/picker.min.css";
@import "odometer";
@media print {
  .breadcrumb {
    display: none !important;
  }
}
input.static {
  border: 1px solid #526783;
  background: transparent;
  border-radius: 0;
  width: 100%;
  &:focus {
    outline: none;
  }
}
input.form-control.ng-invalid.ng-touched {
  color: #d0021b;
  border-color: #d0021b;
  display: block;
}

textarea {
  resize: none;
}

textarea.form-control.ng-invalid.ng-touched {
  color: #d0021b;
  border-color: #d0021b;
  display: block;
}

input.form-control.ng-valid.ng-touched
  textarea.form-control.ng-valid.ng-touched {
  border-color: #4ee17b;
}

select.form-control.ng-valid.ng-touched {
  border-color: #4ee17b;
}

input.form-control.ng-valid.ng-touched {
  border-color: #4ee17b;
}

.hasErrorSpan {
  font-size: 12px;
  color: #ff5151;
  display: block;
}

.hideData {
  display: none;
}

.ng-select.ng-touched {
  .ng-has-value {
    .ng-placeholder {
      display: none;
    }
  }
}

.ng-dropdown-panel-items.scroll-host {
  max-height: 150px !important;
  background: transparent;
  padding: 0 5px;
  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background: $color-blue-lightest-2;
  }

  &::-webkit-scrollbar-thumb {
    background: $color-blue-lighter;
    border-radius: 12px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  .ng-option {
    padding: 5px;
    &.ng-option-marked {
      background: #678cfc26;
      color: #678cfc;
    }
  }
}

.smallSelect {
  .ng-dropdown-panel-items.scroll-host {
    .ng-option {
      color: #97a4b5;
      padding: 2px 4px;
    }
  }
}

.textWrap {
  .ng-dropdown-panel-items.scroll-host {
    .ng-option {
      white-space: normal !important;
    }
  }
}

#box_depot_location {
  .add-depot-link {
    cursor: pointer;
    background: #e6e9f1 !important;
    padding-left: 40px !important;
    position: relative !important;
    border-top: 1px solid #162e4d !important;
    padding: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    &::before {
      position: absolute;
      content: "";
      left: 10px;
      top: 10px;
      width: 16px;
      height: 16px;
      background: url("../assets/images/svg/x.svg") no-repeat;
      transform: rotate(45deg);
    }
    a {
      text-decoration: none;
      color: #162e4d;
    }
  }
}

.default-depots-container-add {
  .ng-dropdown-panel .scroll-host {
    overflow-y: hidden !important;
  }
}

#box_depot_location {
  // .ng-dropdown-panel-items.scroll-host {
  //   .ng-option {
  //     &:last-child {
  //       background: #e6e9f1;
  //       padding-left: 40px;
  //       position: relative;
  //       border-top: 1px solid #162e4d;

  //       &::before {
  //         position: absolute;
  //         content: "";
  //         left: 10px;
  //         top: 10px;
  //         width: 16px;
  //         height: 16px;
  //         background: url("../assets/images/svg/x.svg") no-repeat;
  //         transform: rotate(45deg);
  //       }
  //     }
  //   }
  // }
  &.ng-select-filtered {
    .ng-dropdown-panel-items.scroll-host {
      .ng-option {
        &:last-child {
          background: none;
          padding-left: 5px;
          position: relative;
          border-top: 0;
          &::before {
            position: static;
            content: "";
            left: 10px;
            top: 10px;
            width: 16px;
            height: 16px;
            background: none;
            transform: rotate(45deg);
          }
        }
      }
    }
  }
}
.ng-dropdown-panel {
  padding: 5px;
  // padding-right: 5px;
  background-color: #f3f5fa;
  box-shadow: 0px 24px 35px rgba(0, 0, 0, 0.17);
  border-radius: 12px;
  // @media (max-height: 700px) {
  //   margin-top: -200px;
  // }
}

.ng-select .ng-arrow-wrapper .ng-arrow,
.ng-select.ng-select-opened > .ng-select-container .ng-arrow {
  border: 0;
  width: 16px !important;
  height: 16px !important;
  background: url("../assets/images/svg/icn-dropdown.svg") no-repeat right;
  top: 0;
}
.ng-select .ng-arrow-wrapper .ng-arrow,
.ng-select > .ng-select-container .ng-arrow {
  border: 0;
  width: 16px !important;
  height: 16px !important;
  background: url("../assets/images/svg/icn-dropdown.svg") no-repeat right;
  top: 0;
}
.ng-select-container {
  height: calc(100% + 5px);
}

// .ng-select.ng-select-single .ng-select-container {
//   height: 25px;
// }
// .ng-select .ng-select-container {
//   border-radius: 0;
//   background: none;
//   box-shadow: none;
//   min-height: 25px;
//   border: 0;
//   border-bottom: 1px solid #162e4d;
// }
// .ng-select.ng-touched.ng-invalid .ng-select-container {
//   border-color: #ff5151;
// }
// .ng-select.ng-touched.ng-valid .ng-select-container {
//   border-color: #4ee17b;
// }
// .ng-select.ng-select-focused:not(.ng-select-opened)>.ng-select-container {
//   border-color: none;
//   box-shadow: none;
// }
// .ng-select .ng-select-container .ng-value-container,
// .ng-select.ng-select-single .ng-select-container .ng-value-container .ng-input {
//   padding-left: 0;
// }
// .ng-select .ng-select-container .ng-value-container .ng-input input {
//   margin-top: 6px;
// }
// .ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
//   padding: 2px 5px;
//   color: #97a4b5;
//   border: 0 !important;
// }
// .ng-dropdown-panel {
//   border: 0px solid rgba(0, 0, 0, 0.5);
//   border-radius: 0px;
//   box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
// }
// .ng-dropdown-panel .scroll-host {
//   background: #fff;
// }
// .ng-dropdown-panel.ng-select-bottom {
//   border-radius: 0;
// }
// .ng-select .ng-arrow-wrapper {
//   padding: 0;
//   text-align: right !important;
// }
// .ng-select.ng-select-opened>.ng-select-container {
//   background: none;
// }
// .ng-select .ng-arrow-wrapper .ng-arrow,
// .ng-select.ng-select-opened>.ng-select-container .ng-arrow {
//   border: 0;
//   width: 16px !important;
//   height: 16px !important;
//   background: url("../assets/images/svg/icn-dropdown.svg") no-repeat right;
//   top: 0;
// }
// .ng-select .ng-select-container .ng-value-container .ng-placeholder {
//   color: #97a4b5;
//   margin-top: 5px;
//   display: inline-block;
// }
// .ng-has-value{
//   .ng-placeholder{
//     display: none;
//     }
// }
// .ng-select-focused,
// .ng-select-opened{
//   .ng-value-label{
//     line-height: 20px;
//     margin-top: 5px;
//     display: inline-block;
//   }
// }
// .middle .ng-select .ng-select-container,
// .middle .ng-select .ng-select-container .ng-input input {
//   color: #fff;
//   border-color: #fff;
// }
// .middle .ng-select .ng-select-container .ng-value-container .ng-placeholder {
//   color: #526783;
// }
// .middle .ng-select .ng-arrow-wrapper .ng-arrow,
// .middle .ng-select.ng-select-opened>.ng-select-container .ng-arrow {
//   background: url("../assets/images/svg/icn-dropdown-white.svg") no-repeat right;
// }
// .middle .ng-select.ng-select-opened>.ng-select-container {
//   background: #162e4d;
// }
.modal-extra-lg {
  min-width: 1000px !important;
}

div.pac-container {
  z-index: 100000 !important;
}

.colorPanel span {
  margin: 4px;
  display: inline-block;
  width: calc(100% / 6 - 8px);
  text-align: center;
  height: 30px;
  line-height: 30px;
  color: #fff;
  cursor: pointer;
  overflow: hidden;
}

.colorPanel span.active {
  position: relative;
  box-shadow: 0px 0px 0px 1px #000 inset;
  &::after {
    position: absolute;
    left: 7px;
    content: "";
    top: 11px;
    width: 15px;
    height: 7px;
    border: 2px solid #162e4d;
    border-top: 0;
    border-right: 0;
    transform: rotate(-62deg);
  }
}

.marginR25 {
  margin-right: 25px;
}

.cursorPointer {
  cursor: pointer !important;
}

.cursorDisabled {
  cursor: not-allowed !important;
}

.big-modal-dialog-fullscreen {
  max-width: 90% !important;
  width: 100% !important;
}

.big-modal-dialog-img-preview {
  max-width: 90% !important;
  height: 70vh;
  width: 70vw;
  img {
    height: 70vh;
    width: 100%;
  }
  .arrows {
    width: 48px;
    height: 48px;
    color: #000;
    border-color: #fff;
    position: absolute;
    top: 50%;
    margin-top: -31px;
  }
  .prev {
    border-bottom: 6px solid;
    border-left: 6px solid;
    transform: rotate(45deg);
    left: 10px;
  }
  .next {
    border-bottom: 6px solid;
    border-left: 6px solid;
    transform: rotate(-135deg);
    right: 10px;
  }
}

.owl-dt-calendar-table .owl-dt-calendar-cell-content {
  font-size: 12px !important;
  border-radius: 0;
}

.owl-dt-calendar-table .owl-dt-calendar-cell-selected,
.owl-dt-calendar-table
  .owl-dt-calendar-cell-active:focus
  > .owl-dt-calendar-cell-content:not(.owl-dt-calendar-cell-selected),
.owl-dt-calendar-table
  :not(.owl-dt-calendar-cell-disabled):hover
  > .owl-dt-calendar-cell-content:not(.owl-dt-calendar-cell-selected),
.owl-dt-calendar-table
  .owl-dt-calendar-cell-today:not(.owl-dt-calendar-cell-selected) {
  background-color: #162e4d;
  color: #ffffff;
}

.owl-dt-calendar-control {
  border-bottom: 1px solid #162e4d;
}

.owl-dt-calendar-header {
  text-align: center;
}

.owl-dt-calendar-table .owl-dt-calendar-cell {
  padding: 0;
}

.owl-dt-trigger-disabled {
  cursor: not-allowed !important;
}

.containerDetails {
  .owl-dt-calendar-control {
    display: none;
  }
}

div.block-ui-wrapper {
  background: transparent !important;
  z-index: 999 !important;
}

.light {
  .middle .ng-select.ng-select-opened > .ng-select-container {
    background: none;
  }
  .middle .ng-select .ng-select-container,
  .middle .ng-select .ng-select-container .ng-input input {
    color: #162e4d;
    border-color: #162e4d;
  }
  .middle .ng-select .ng-arrow-wrapper .ng-arrow,
  .middle .ng-select.ng-select-opened > .ng-select-container .ng-arrow {
    background: url("../assets/images/svg/icn-dropdown.svg") no-repeat right;
  }
}

.blackBack {
  background: black;
}

.yellowBack {
  background: yellow;
}

.bulkPreviewActive {
  div.productListing {
    border-color: #4ee17b !important;
  }
}

marketplace-seller-dashboard-home {
  .middle {
    width: 100%;
  }
  .rowsm {
    margin-left: -5px;
    margin-right: -5px;
  }
  .rowsm .colsm {
    padding-left: 5px;
    padding-right: 5px;
  }
  .light .well {
    background: #fff;
    padding: 10px;
    margin-bottom: 10px;
  }
  .well .table {
    margin-bottom: 0;
  }
  .well h2 {
    font-size: 24px;
    font-weight: bold;
    margin: 0;
    margin-bottom: 10px;
  }
  .well h2 span {
    font-weight: normal;
  }
  .well .well {
    border: 1px solid #c0c8d4;
    background: #f7f9fb;
    margin: 0;
    margin-top: 3px;
    overflow: hidden;
  }
  .table.borderedBody th {
    padding-top: 0;
  }
  .table.borderedBody td {
    color: #000;
  }
  .table.borderedBody th:last-child {
    min-width: 100px;
  }
  .table td.heading {
    border: 0 !important;
    padding: 0 !important;
    background: #fff !important;
    color: #526783;
    font-weight: bold;
  }
  .stepper {
    margin: 5px 0;
  }
  .stepper ul {
    margin: 0 -14%;
  }
  .chart-legends {
    padding: 0;
    margin: 0;
    list-style-type: none;
    font-size: 12px;
  }
  .chart-legends span {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: 10px;
  }
  @media only screen and (max-width: 576px) {
    .rightSection {
      width: calc(100% - 95px);
    }
  }
}

marketplace-buyer-dashboard-home {
  .middle {
    width: 100%;
  }
  .rowsm {
    margin-left: -5px;
    margin-right: -5px;
  }
  .rowsm .colsm {
    padding-left: 5px;
    padding-right: 5px;
  }
  .light .well {
    background: #fff;
    padding: 10px;
    margin-bottom: 10px;
  }
  .well .table {
    margin-bottom: 0;
  }
  .well h2 {
    font-size: 24px;
    font-weight: bold;
    margin: 0;
    margin-bottom: 10px;
  }
  .well h2 span {
    font-weight: normal;
  }
  .well .well {
    border: 1px solid #c0c8d4;
    background: #f7f9fb;
    margin: 0;
    margin-top: 3px;
    overflow: hidden;
  }
  .table.borderedBody th {
    padding-top: 0;
  }
  .table.borderedBody td {
    color: #000;
  }
  .table.borderedBody th:last-child {
    min-width: 100px;
  }
  .table td.heading {
    border: 0 !important;
    padding: 0 !important;
    background: #fff !important;
    color: #526783;
    font-weight: bold;
  }
  ul.newsletter {
    padding: 0;
    margin: 0;
    list-style-type: none;
  }
  ul.newsletter li {
    margin-top: 4px;
    border: 1px solid #c0c8d4;
    background: #f7f9fb;
    padding: 8px;
  }
  .stepper {
    margin: 5px 0;
  }
  .stepper ul {
    margin: 0 -14%;
  }
  .chart-legends {
    padding: 0;
    margin: 0;
    list-style-type: none;
    font-size: 12px;
  }
  .chart-legends span {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: 10px;
  }
  @media only screen and (max-width: 576px) {
    .rightSection {
      width: calc(100% - 95px);
    }
  }
}

.containerRow {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

marketplace-video-player-modal {
  line-height: 0px;
}

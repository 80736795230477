@font-face {
    font-family: 'Lato';
    src: url('Lato-BlackItalic.woff2') format('woff2'),
        url('Lato-BlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Lato Hairline';
    src: url('Lato-HairlineItalic.woff2') format('woff2'),
        url('Lato-HairlineItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    src: url('Lato-Light.woff2') format('woff2'),
        url('Lato-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    src: url('Lato-Regular.woff2') format('woff2'),
        url('Lato-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    src: url('Lato-Bold.woff2') format('woff2'),
        url('Lato-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lato Hairline';
    src: url('Lato-Hairline.woff2') format('woff2'),
        url('Lato-Hairline.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    src: url('Lato-BoldItalic.woff2') format('woff2'),
        url('Lato-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    src: url('Lato-LightItalic.woff2') format('woff2'),
        url('Lato-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    src: url('Lato-Black.woff2') format('woff2'),
        url('Lato-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Lato';
    src: url('Lato-Italic.woff2') format('woff2'),
        url('Lato-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}


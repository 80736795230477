.pac-container {
  border-radius: 10px;
}
.pac-icon {
  display: none;
  padding: 2rem;
}
.pac-item {
  border: none;
}
.pac-item:hover {
  color: $color-cyan;
  .pac-item-query {
    color: $color-cyan;
  }
  .pac-matched {
    color: $color-cyan;
  }
}
.pac-item-query {
  color: $color-blue-lighter;
}
.pac-matched {
  color: $color-blue-light;
}

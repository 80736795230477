.bs-datepicker {
  box-shadow: none !important
}

div.bs-datepicker-container {
  padding: 0px !important;
  overflow: hidden;
  color: #162e4d;

  .bs-datepicker-head {
    background: none;
    border-bottom: 1px solid #162e4d;

    button {
      color: #162e4d;
    }

    .bs-datepicker-head button.next,
    .bs-datepicker-head button.previous {
      border-radius: 0 !important;
      padding-left: 0 !important;
      padding-right: 0 !important;
      width: auto;
    }
  }
}

.bs-datepicker-head,
.bs-datepicker-body {
  width: auto !important;
}

.bs-datepicker-head button {
  border-radius: 0 !important;
  padding: 0 !important;
  font-size: 16px;
  font-weight: 700;
  width: auto !important;

  &:hover {
    background: #fff !important;
  }

  &.previous,
  &.next {
    border: 1px solid #526783;
    width: 14px !important;
    height: 14px !important;
    transform: rotate(45deg);
    margin-top: 8px;
  }

  &.previous {
    border-top: 0;
    border-right: 0;
  }

  &.next {
    border-bottom: 0;
    border-left: 0;
  }

  &.previous span,
  &.next span {
    font-size: 0px !important;

  }
}

.bs-datepicker-head button.current:first {
  margin-right: -30px !important;
}

.bs-datepicker-body th {
  text-transform: uppercase;
  color: #526783 !important;
  font-size: 12px !important;
}
.bs-datepicker-body table td span.disabled, .bs-datepicker-body table td.disabled span{
  background: none;
  opacity: 0.5;
  cursor: not-allowed !important;
}
.bs-datepicker-body table.days span{
  border-radius: 0 !important;
  color: #162e4d !important;
  width: 20px !important;
  height: 20px !important;
  line-height: 20px !important;
  margin: 5px !important;
  font-size: 12px !important;

  &.is-other-month {
    color: #dfdfdf !important;
  }

  &.selected {
    color: #fff !important;
    background: #162e4d !important;
  }
}
.bs-datepicker-body table span{
   border-radius: 0 !important;
}
